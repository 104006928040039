import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";

export default function Breadcrumbs({ pageTitle }) {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "breadcrumb.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      className="image-section"
      fluid={[
        `linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.70))`,
        query.bannerImg.childImageSharp.fluid,
      ]}
      backgroundColor={`#040e18`}
    >
      <div className="breadcrumb breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner text-center">
                <div className="breadcrumb-text">
                  <h1>{pageTitle}</h1>
                  <p className="breadcrumb-elements">
                    <span>
                      <span>
                        <Link to="/">Home</Link> <i />
                        <span className="breadcrumb_last" aria-current="page">
                          {pageTitle}
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
}
