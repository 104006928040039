import React from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default () => {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "logo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImg2: file(relativePath: { eq: "breadcrumb.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="O nas">
      <SEO title="O nas" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Zajmujemy się sprzedażą kawy i herbaty, ale nie tylko!</h2>
            <strong>
              Gwarantujemy szeroki asortyment urządzeń gastronomicznych, chemii
              czyszczącej oraz profesjonalny serwis w oparciu o 10 letnie
              doświadczenie.
            </strong>
            <p>
              Zaopatrzymy Twoją restaurację, kawiarnię czy biuro w wysokiej
              jakości produkty o aromacie stawiającym na nogi nawet w
              poniedziałek o 7 rano!
            </p>
            <p>
              Kluczowe jest dla nas zadowolenie klientów. Skontaktuj sie z nami
              i przekonaj się że to nie tylko chwytliwe hasło.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Img fluid={query.bannerImg.childImageSharp.fluid} alt="Banner" />
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h2>Skad się wzięliśmy?</h2>
            <p>
              <b>Maciek,</b> w branży od 2007 roku. Sukcesywnie nabierając pewnosci i
              doświadczenia zgłębiał tajniki palenia i parzenia dobrej kawy. W
              2017 roku wziął los w swoje rece. Po tych kilkunastu latach
              skromnie mówi, że wie co robi i czerpie z tego przyjemność. Ciągle
              chce więcej, dlatego z każdym miesiącem rozszerza ofertę i zakres
              usług.
            </p>
            <p>
              <b>Mateusz,</b> młody i ambitny chłopak, powiększył, do tej pory
              jednoosobową, ekipę w 2019 roku. W branży gastronomicznej od kilku
              lat, zdobywał doświadczenie w trojmiejskich lokalach. Z wielkim
              sercem i pasja podchodzi do tematu kawy. Trochę buntownik, trochę
              artysta. Odpowiedzialny za szkolenia i treningi, bo nikt inny nie
              leje mleka z taką gracją.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
